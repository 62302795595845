import React, { useState, useRef } from "react";
import cn from "classnames";
import SearchResultLoader from "../ui/loaders/search-result-loader";
import { useUI } from "@/contexts/ui.context";
import SearchBox from "./search-box";
import { event } from "@/lib/ga";
import SearchProduct from "./search-product";
import useScrollLock from "@/utils/use-scroll-lock";
// import useOnClickOutside from "@/utils/use-click-outside";
import { AnimatePresence, m } from "@/lib/framer-motion";
import { InfiniteHits } from "./infinite-hits";
import { InstantSearch, Pagination } from "react-instantsearch";
// import { connectSearchBox } from "react-instantsearch";
import { useSearchBox, useInstantSearch, Configure } from "react-instantsearch";
// import { connectInfiniteHits } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

// const SearchInput = connectSearchBox(SearchBox);

export default function Search() {
  const { displaySearch, closeSearch, showToast } = useUI();

  useScrollLock(displaySearch);
  // const payload = {
  //   search_term: searchText,
  // };
  // event("search", payload);

  function Hit({ hit }) {
    return (
      <div
        className=" p-5 border-b border-gray-150 relative last:border-b-0"
        onClick={closeSearch}
        key={hit.objectID}
      >
        <SearchProduct product={hit} />
      </div>
    );
  }
  return (
    <InstantSearch searchClient={searchClient} indexName="product">
      <Configure hitsPerPage={20} />
      <AnimatePresence>
        {displaySearch && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 100,
            }}
            exit={{ opacity: 0 }}
            key="overlay"
            className={
              "fixed bg-black/50 top-0 left-0 z-[99999] transition duration-300 ease-in-out px-4 w-full h-screen"
            }
          >
            <div className="w-full md:w-[730px] lg:w-[930px] mx-auto">
              <div className="mt-3.5 lg:mt-4 w-full">
                <div className="flex flex-col mx-auto mb-1.5 w-full ">
                  <SearchBox />
                </div>

                <div className="h-[500px] overflow-y-scroll bg-white rounded-md">
                  <ShowStatus />
                  <InfiniteHits hitComponent={Hit} />
                </div>
              </div>
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </InstantSearch>
  );
}
const ShowStatus = () => {
  const { status } = useInstantSearch();
  const isSearchStalled = status === "stalled";
  return (
    <span
      hidden={!isSearchStalled}
      className="text-body text-2xl text-center px-8 py-2"
    >
      Searching…
    </span>
  );
};
