import { NextSeo } from "next-seo";

export const DefaultSeo = () => {
  return (
    <NextSeo
      title="Hype Fly India | Premium and Limited Sneakers and Streetwear"
      defaultTitle="Hype Fly India | Premium and Limited Sneakers and Streetwear"
      description="Shop From A Wide Range of Brands: Jordans, Yeezys, Dunks, Fear of God Essentials, Limited Edition & Authentic Products Online With Fast & Safe Delivery."
      canonical="https://hypefly.co.in/"
      openGraph={{
        type: "website",
        locale: "en_IE",
        site_name: "Hypefly",
        url: "https://hypefly.co.in",
        title: "Hype Fly India | Premium and Limited Sneakers and Streetwear",
        description:
          "Shop From A Wide Range of Brands: Jordans, Yeezys, Dunks, Fear of God Essentials, Limited Edition & Authentic Products Online With Fast & Safe Delivery.",

        images: [
          {
            url: "https://i.imgur.com/HzS2zef.png",
            width: 140,
            height: 140,
            alt: "Hypefly",
          },
        ],
      }}
      twitter={{
        // handle: "@handle",
        // site: "@site",
        cardType: "summary_large_image",
      }}
      additionalMetaTags={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1 maximum-scale=1",
        },
        {
          name: "apple-mobile-web-app-capable",
          content: "yes",
        },
        {
          name: "theme-color",
          content: "#ffffff",
        },
      ]}
      additionalLinkTags={[
        {
          rel: "icon",
          href: `/favicon.ico`,
        },
        {
          rel: "apple-touch-icon",
          href: "/favicon.ico",
        },
        // {
        //   rel: "manifest",
        //   href: "/manifest.json",
        // },
      ]}
    />
  );
};
