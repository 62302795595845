import React from "react";
import Link from "next/link";
export default function TopBar() {
  return (
    <Link
      id="topBar"
      href={"/collections/all-sneakers"}
      className="flex justify-center items-center hover:text-white/80 transition-all duration-300 ease text-white md:text-sm text-[10px] font-avenir font-medium text-center w-full bg-[#1A202C] lg:h-10 h-8"
    >
      FREE SHIPPING ABOVE 24,999/- | SHOP NOW
    </Link>
  );
}
