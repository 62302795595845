import React, { useRef } from "react";
import { useSession } from "next-auth/react";
import Hamburger from "@/components/ui/hamburger";
import { siteSettings } from "@/settings/site-settings";
import HeaderMenu from "./header-menu";
import Logo from "@/components/ui/logo";
import { useUI } from "@/contexts/ui.context";
import AuthMenu from "./auth-menu";
import CartButton from "../../cart/cart-button";
import SearchIcon from "@/components/icons/search-icon";
import UserIcon from "@/components/icons/user-icon";

const { site_header } = siteSettings;
const Header = () => {
  const { data: session } = useSession();
  const { openSearch, openModal, setModalView } = useUI();
  const siteHeaderRef = useRef();

  function handleLogin() {
    setModalView("LOGIN_VIEW");
    return openModal();
  }

  return (
    <header
      id="siteHeader"
      ref={siteHeaderRef}
      className="z-20 w-full h-16 sm:h-20 lg:h-24 sticky top-0"
    >
      <div className="z-20 w-full h-16 text-gray-700 transition duration-200 ease-in-out bg-white innerSticky body-font sm:h-20 lg:h-24 px-4 md:px-8 lg:px-[5vw] py-2 lg:py-0">
        <div className="flex items-center lg:justify-between max-w-[1920px] h-full w-full">
          <Hamburger />
          <div className="lg:flex hidden h-full items-center justify-center">
            <Logo />
          </div>

          <HeaderMenu data={site_header.menu} className="hidden lg:flex" />

          <div className="items-center lg:justify-end justify-start lg:ml-0 ml-6 flex-shrink flex gap-x-6 lg:gap-x-3 xl:gap-x-4 2xl:gap-x-4 lg:w-fit w-full">
            <button
              className="relative flex items-center justify-center flex-shrink-0 h-auto transform focus:outline-none"
              onClick={openSearch}
              aria-label="search-button"
            >
              <SearchIcon width={20} height={22} />
            </button>
            <div className="lg:hidden absolute top-2 left-[calc(50%-5rem)] justify-center items-center">
              <Logo position="center" />
            </div>
            <div className="-mt-0.5 flex-shrink-0 flex items-center ml-auto">
              <AuthMenu
                isAuthorized={session ? true : false}
                href={"/my-account"}
                className="text-sm font-semibold hover:underline xl:text-base text-heading"
                btnProps={{
                  className:
                    "text-base xl:text-base text-heading font-semibold focus:outline-none",
                  children: <UserIcon className="cursor-pointer" />,
                  onClick: handleLogin,
                }}
              >
                Account
              </AuthMenu>
            </div>
            <CartButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
