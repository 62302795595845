import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Input from "@/components/ui/input";
import { BiMailSend } from "react-icons/bi";
import { useMutation } from "@/framework/queries/graphql";
import { QUERY_ENDPOINTS } from "@/framework/utils/query-endpoints";
import { useUI } from "@/contexts/ui.context";
import { useRouter } from "next/router";

const ErrorMessage = ({ children }) => (
  <div className="text-sm text-red mt-2" role="alert">
    {children}
  </div>
);
const SubmitButton = ({ processing, error, children, disabled, ...props }) => (
  <button
    className={`w-fit bg-black border-[1px] h-10 border-white/50 hover:border-white rounded-lg text-2xl px-2.5 py-1 group flex items-center hover:bg-black/80 hover:text-black transition-all duration-300 ease ${
      error ? "SubmitButton--error" : ""
    }`}
    type="submit"
    aria-label="submit button"
    disabled={processing || disabled}
    {...props}
  >
    {processing ? "..." : children}
  </button>
);
// const defaultValues = {
//     email: "",
// };
const CustomForm = () => {
  const router = useRouter();
  const { showToast } = useUI();
  const [{ fetching: loading, data, error }, subscribe] = useMutation(
    QUERY_ENDPOINTS.SUBSCRIBE
  );

  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const onError = (errors, e) => {
    //console.log(errors, e);
  };
  useEffect(() => {
    reset();
    setStatus(false);
  }, [router.pathname, reset]);
  const onSubmit = async (data) => {
    setProcessing(true);
    try {
      const result = await subscribe({
        email: data?.email,
        phone: "",
      });
      setProcessing(false);
      setStatus(true);
      reset();
    } catch (err) {
      setProcessing(false);
      showToast(err.message, "error");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className="flex flex-col justify-start items-start w-full"
      noValidate
    >
      {status && (
        <div
          className="text-center text-white border-dashed border-[1px] border-white rounded px-3 py-2 w-full text-sm font-medium"
          dangerouslySetInnerHTML={{ __html: "Thank you for subscribing!" }}
        />
      )}

      {!status ? (
        <div className="flex flex-col gap-1">
          <div className="w-full flex flex-row gap-2 items-start">
            <Input
              placeholderKey="Enter your email"
              name="email"
              type="email"
              variant="solid"
              {...register("email", {
                required: `${"Please provide your email address"}`,
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please provide a valid email address",
                },
              })}
              errorKey={errors.email?.message}
            />
            <SubmitButton
              processing={processing}
              error={error}
              disabled={false}
            >
              <BiMailSend className="text-white" />
            </SubmitButton>
          </div>
          {error && (
            <ErrorMessage>
              {error?.message == "This attribute must be unique"
                ? "Email ID is already subscribed."
                : error?.message}
            </ErrorMessage>
          )}
        </div>
      ) : null}
    </form>
  );
};

const ContactForm = (props) => {
  return (
    <div className="w-full z-[1]">
      <CustomForm />
    </div>
  );
};

export default ContactForm;
