import Link from "next/link";
import Image from "next/image";
import { m } from "@/lib/framer-motion";
import { fadeInOut } from "@/utils/motion/fade-in-out";
import { IoIosCloseCircle } from "react-icons/io";
import Counter from "../common/counter";
import { useCart } from "@/contexts/cart/cart.context";
import usePrice from "@/framework/product/use-price";
import { event } from "@/lib/ga";
import * as fbq from "../../lib/fpixel";
const CartItem = ({ item }) => {
  const {
    addItemToCart,
    removeItemFromCart,
    clearItemFromCart,
    getItemFromCart,
  } = useCart();
  // const { price, basePrice, discount } = usePrice({
  //   amount: productVariants[0]?.salePrice,
  //   baseAmount: productVariants[0]?.compareAtPrice,
  //   currencyCode: "INR",
  // });
  const { price } = usePrice({
    amount: item.attributes.salePrice,
    currencyCode: "INR",
  });
  const { price: totalPrice } = usePrice({
    amount: item.itemTotal,
    currencyCode: "INR",
  });
  return (
    <m.div
      layout
      initial="from"
      animate="to"
      exit="from"
      variants={fadeInOut(0.25)}
      className={`group w-full h-auto flex justify-start items-center bg-white py-4 md:py-7 border-b border-gray-100 relative last:border-b-0`}
      title={item?.name}
    >
      <div className="relative flex flex-shrink-0 w-24 h-24 overflow-hidden bg-gray-200 rounded-md cursor-pointer md:w-28 md:h-28 mr-4">
        <Image
          src={item?.image ?? "/assets/placeholder/cart-item.svg"}
          fill
          loading="eager"
          alt={item.name || "Product Image"}
          className="object-contain bg-gray-300"
          sizes="(max-width: 768px) 500px, (max-width: 1200px) 500px, 400px"
        />
        <div
          className="absolute top-0 flex items-center justify-center w-full h-full transition duration-200 ease-in-out bg-black left-0 bg-opacity-0 group-hover:bg-opacity-30"
          onClick={() => {
            const singleItem = getItemFromCart(item.slug);
            event("remove_from_cart", {
              currency: "INR",
              value: singleItem?.itemTotal,
              items: [
                {
                  item_id: singleItem?.id,
                  item_name: singleItem?.name,
                  item_category: singleItem?.productType,
                  item_variant: singleItem?.attributes?.size,
                  price: singleItem?.attributes?.salePrice,
                  quantity: singleItem?.quantity,
                },
              ],
            });
            clearItemFromCart(item.slug, item?.attributes?.size);
          }}
          role="button"
        >
          <IoIosCloseCircle className="relative text-2xl text-white transition duration-300 ease-in-out transform scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100" />
        </div>
      </div>

      <div className="flex flex-col w-full overflow-hidden">
        <Link
          href={`/products/${item?.slug}`}
          className="text-sm lg:text-[15px] text-body font-semibold mb-1.5 -mt-1 leading-normal"
        >
          {item.name}
        </Link>
        {/* @ts-ignore */}
        <span className="text-sm text-gray-600 mb-2.5">
          Unit Price : &nbsp; {price}
        </span>

        <div className="flex items-end justify-between">
          <Counter
            quantity={item.quantity}
            onIncrement={() => {
              addItemToCart(item, 1);
              const singleItem = getItemFromCart(item.slug);
              event("add_to_cart", {
                currency: "INR",
                value: singleItem?.attributes?.salePrice,
                items: [
                  {
                    item_id: singleItem?.id,
                    item_name: singleItem?.name,
                    item_category: singleItem?.productType,
                    item_variant: singleItem?.attributes?.size,
                    price: singleItem?.attributes?.salePrice,
                    quantity: 1,
                  },
                ],
              });
              fbq.event("AddToCart", {
                currency: "INR",
                value: singleItem?.attributes?.salePrice,
              });
            }}
            onDecrement={() => {
              const singleItem = getItemFromCart(item.slug);
              event("remove_from_cart", {
                currency: "INR",
                value: singleItem?.attributes?.salePrice,
                items: [
                  {
                    item_id: singleItem?.id,
                    item_name: singleItem?.name,
                    item_category: singleItem?.productType,
                    item_variant: singleItem?.attributes?.size,
                    price: singleItem?.attributes?.salePrice,
                    quantity: 1,
                  },
                ],
              });

              removeItemFromCart(item.slug, item?.attributes?.size);
            }}
            variant="dark"
          />
          <span className="text-sm font-semibold leading-5 md:text-base text-heading">
            {totalPrice}
          </span>
        </div>
      </div>
    </m.div>
  );
};

export default CartItem;
