// export const event = (action, value) => {
//   if (typeof window !== "undefined" && typeof window.gtag === "function") {
//     window.gtag("event", action, value);
//   }
// };

import { sendGAEvent } from "@next/third-parties/google";
export const event = (action, value) => {
  sendGAEvent("event", action, value);
};
