import {
  HOME_ACCESSORIES_CAROUSEL,
  HOME_STREET_CAROUSEL,
  HOME_GRAILS_MINI_CAROUSEL,
} from "../queries/carousel";
import { QUERY_ALL_BRANDS_BY_COLLECTION } from "../queries/brands";
import {
  QUERY_ALL_COLLECTIONS,
  QUERY_COLLECTION_BY_SLUG,
  QUERY_PAIR_WITH_PRODUCTS,
  QUERY_PRODUCTS_COLLECTION_BY_SLUG,
  QUERY_OUT_OF_STOCK_PRODUCTS_COLLECTION_BY_SLUG,
  QUERY_COLLECTION_BY_SLUG_ALL_PRODUCTS,
} from "../queries/collections";

import { All_WAREHOUSES, UPDATE_WAREHOUSE } from "../queries/warehouse";

import { FAQ } from "../queries/faq";

import {
  QUERY_ALL_PRODUCT_CATEGORIES,
  QUERY_ALL_PRODUCT_CATEGORIES_BY_COLLECTION,
} from "../queries/product-categories";

import { QUERY_BLOG, POST_BY_SLUG } from "../queries/blog";
import { QUERY_AUTHORS, AUTHOR_BY_SLUG } from "../queries/team";

import {
  CREATE_ORDER,
  GET_ORDER,
  GET_ORDERS_BY_USER,
  UPDATE_ORDER,
} from "../queries/order";
import {
  QUERY_PRODUCT_BY_SLUG,
  QUERY_ALL_PRODUCTS,
  QUERY_PRODUCTS,
} from "../queries/products";

import { CREATE_SIZE_REQUEST } from "../queries/size-requests";

import { QUERY_ALL_TYPES_BY_COLLECTION } from "../queries/product-type";

import {
  LOGIN,
  ME,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../queries/auth";

import { QUERY_GET_PROMO, QUERY_UPDATE_PROMO } from "../queries/promo-codes";
import { SEARCH_PRODUCTS } from "../queries/search";

import {
  All_TESTIMONIALS,
  All_CELEBS,
  All_CUSTOMERS_GALLERY,
  STEPS,
  INSTAGRAM_CAROUSEL,
  TIMER,
} from "../queries/testimonials";

import { SUBSCRIBE } from "../queries/common";

export const QUERY_ENDPOINTS = {
  SUBSCRIBE: SUBSCRIBE,
  HOME_ACCESSORIES_CAROUSEL: HOME_ACCESSORIES_CAROUSEL,
  HOME_STREET_CAROUSEL: HOME_STREET_CAROUSEL,
  HOME_GRAILS_MINI_CAROUSEL: HOME_GRAILS_MINI_CAROUSEL,
  QUERY_ALL_BRANDS_BY_COLLECTION: QUERY_ALL_BRANDS_BY_COLLECTION,
  GET_ALL_TYPES_BY_COLLECTION: QUERY_ALL_TYPES_BY_COLLECTION,
  PRODUCT_CATEGORIES: QUERY_ALL_PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES_BY_COLLECTION: QUERY_ALL_PRODUCT_CATEGORIES_BY_COLLECTION,
  COLLECTIONS: QUERY_ALL_COLLECTIONS,
  COLLECTION_BY_SLUG: QUERY_COLLECTION_BY_SLUG,
  PRODUCTS_COLLECTION_BY_SLUG: QUERY_PRODUCTS_COLLECTION_BY_SLUG,
  OUT_OF_STOCK_PRODUCTS_COLLECTION_BY_SLUG:
    QUERY_OUT_OF_STOCK_PRODUCTS_COLLECTION_BY_SLUG,
  ALL_PRODUCTS: QUERY_ALL_PRODUCTS,
  PRODUCT_BY_SLUG: QUERY_PRODUCT_BY_SLUG,
  CREATE_SIZE_REQUEST: CREATE_SIZE_REQUEST,
  LOGIN: LOGIN,
  ME: ME,
  REGISTER: REGISTER,
  FORGOT_PASSWORD: FORGOT_PASSWORD,
  RESET_PASSWORD: RESET_PASSWORD,
  PAIR_WITH: QUERY_PAIR_WITH_PRODUCTS,
  CREATE_ORDER: CREATE_ORDER,
  GET_ORDER: GET_ORDER,
  GET_PROMO: QUERY_GET_PROMO,
  UPDATE_PROMO: QUERY_UPDATE_PROMO,
  GET_ORDERS_BY_USER: GET_ORDERS_BY_USER,
  UPDATE_ORDER: UPDATE_ORDER,
  SEARCH_PRODUCTS: SEARCH_PRODUCTS,
  FAQ: FAQ,
  All_TESTIMONIALS: All_TESTIMONIALS,
  All_CELEBS: All_CELEBS,
  All_CUSTOMERS_GALLERY: All_CUSTOMERS_GALLERY,
  STEPS: STEPS,
  BLOG: QUERY_BLOG,
  POST_BY_SLUG: POST_BY_SLUG,
  INSTAGRAM_CAROUSEL: INSTAGRAM_CAROUSEL,
  TIMER: TIMER,
  All_WAREHOUSES: All_WAREHOUSES,
  UPDATE_WAREHOUSE: UPDATE_WAREHOUSE,
  PRODUCTS: QUERY_PRODUCTS,
  QUERY_COLLECTION_BY_SLUG_ALL_PRODUCTS: QUERY_COLLECTION_BY_SLUG_ALL_PRODUCTS,
  AUTHORS: QUERY_AUTHORS,
  AUTHOR_BY_SLUG: AUTHOR_BY_SLUG,
};
