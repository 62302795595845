import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { m } from "@/lib/framer-motion";
import { IoClose } from "react-icons/io5";
import { fadeInOut } from "@/utils/motion/fade-in-out";
import Image from "next/image";
import Input from "@/components/ui/input";
import Button from "@/components/ui/button";
import { useUI } from "@/contexts/ui.context";
import { useForm } from "react-hook-form";
import { useMutation } from "@/framework/queries/graphql";
import { QUERY_ENDPOINTS } from "@/framework/utils/query-endpoints";
import useOnClickOutside from "@/utils/use-click-outside";
import useScrollLock from "@/utils/use-scroll-lock";

export default function EmailPopupForm() {
  const { displayEmailPopup, closeEmailPopup, showToast } = useUI();
  const [{ error }, subscribe] = useMutation(QUERY_ENDPOINTS.SUBSCRIBE);
  const ref = useRef(null);
  const innerRef = useRef(null);
  useOnClickOutside(innerRef, () => closeEmailPopup());

  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState(false);

  const defaultValues = {
    email: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
  });

  useScrollLock(displayEmailPopup);

  async function onSubmit({ contactEmail, phone }) {
    setProcessing(true);
    try {
      const result = await subscribe({
        email: data?.email,
        phone: "",
      });
      setProcessing(false);
      setStatus(true);
      reset();
    } catch (err) {
      setProcessing(false);
      showToast(error?.message, "error");
    }
  }
  return (
    <m.div
      layout
      initial="from"
      animate="to"
      exit="from"
      variants={fadeInOut(0.3)}
      ref={ref}
      className="z-[99] bg-black/50 flex flex-col justify-start my-auto items-center px-5 h-full py-4 fixed top-0 w-full shadow-fraud left-0"
    >
      <div
        ref={innerRef}
        className="bg-[#021C3D] lg:w-[50vw] w-[90vw] h-fit relative flex flex-col items-center justify-start md:py-16 py-10"
      >
        <IoClose
          className="cursor-pointer absolute top-4 right-4 text-2xl text-white z-1 hover:text-yellow transition-all duration-300 ease"
          onClick={() => closeEmailPopup(false)}
        />

        <Link
          href={"/"}
          className={"lg:w-[14vw] lg:h-[6vw] w-full h-16 relative"}
        >
          <Image
            src="/images/hypefly-white-logo.png"
            alt="hypefly"
            priority={true}
            fill
            quality={10}
            sizes="(max-width: 768px) 160px, (max-width: 1200px) 224px, 224px"
            className="object-contain object-center"
          />
        </Link>

        <div className="relative lg:w-[30vw] lg:h-[15vw] w-full h-48 md:my-8 my-4">
          <Image
            src="/images/dont-miss-out.png"
            alt="don't miss out"
            fill
            sizes="(max-width: 768px) 500px, (max-width: 1200px) 500px, 400px"
            priority={true}
            quality={5}
            className="object-contain w-full"
          />
        </div>
        <p className="text-white text-center lg:text-2xl md:text-xl text-xl md:max-w-[70%] max-w-[90%] font-semibold">
          Be the First to Know About Our Latest Releases and Discounts!
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex-shrink-0 w-full md:px-8 px-4 lg:max-w-[80%] mt-6"
          noValidate
        >
          {status && (
            <div className="text-center text-white border-dashed border-[1px] border-white rounded px-3 py-2 w-full text-base font-medium">
              <p>Thank you for Subscribing!</p>
              <p>Look out for an email from us in your inbox.</p>
            </div>
          )}
          {!status && (
            <div className="flex flex-col gap-2 items-start justify-start">
              <Input
                placeholderKey="EMAIL"
                type="email"
                variant="solid"
                name="contact-email"
                className="w-full"
                inputClassName="px-4 lg:px-7 h-12 text-left sm:text-left bg-white"
                {...register("contactEmail", {
                  required: "Please provide your email.",
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please provide valid email address",
                  },
                })}
                errorKey={errors.contactEmail?.message}
              />
              <Input
                type="tel"
                placeholderKey="PHONE"
                variant="solid"
                className="w-full"
                inputClassName="px-4 lg:px-7 h-12 text-left sm:text-left bg-white"
                {...register("phone", {
                  required: `${"Please provide your phone number"}`,
                  pattern: {
                    value: /^[6789]\d{9}$/,
                    message: "Please provide a valid phone number",
                  },
                })}
                errorKey={errors.phone?.message}
              />
              <Button
                variant="emailPopupSubmit"
                // className="mt-3 sm:mt-0 w-full sm:w-auto sm:ml-2 md:h-full flex-shrink-0"
              >
                <span className="lg:py-0.5">
                  {processing ? "Processing.." : "JOIN THE HYPE!"}
                </span>
              </Button>
            </div>
          )}
        </form>
      </div>
    </m.div>
  );
}
