export const footerData = [
  {
    id: 1,
    widgetTitle: "PAGES",
    lists: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
      {
        id: 2,
        title: "Sneakers",
        path: "/collections/all-sneakers",
      },
      {
        id: 3,
        title: "Streetwear",
        path: "/collections/all-apparel",
      },
      {
        id: 4,
        title: "About",
        path: "/about",
      },
      {
        id: 5,
        title: "Blog",
        path: "/blog",
      },
    ],
  },
  {
    id: 2,
    widgetTitle: "POLICIES",
    lists: [
      {
        id: 1,
        title: "Return Policy",
        path: "/policies/return-policy",
      },
      {
        id: 2,
        title: "Terms of Service",
        path: "/policies/terms-of-service",
      },
      {
        id: 3,
        title: "Seller Policy",
        path: "/policies/seller-policy",
      },
      {
        id: 4,
        title: "Privacy Policy",
        path: "/policies/privacy-policy",
      },
      {
        id: 5,
        title: "Shipping Policy",
        path: "/policies/shipping-policy",
      },
    ],
  },
];
